import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { hitBeApi } from "../../api/api";
import { isCookieExpired } from "../../utils/utils";
import { useDispatch } from "react-redux";
import GoogleLoginComponent from "../utils/LoginWithGoogle";

const countryCodes = [
  "+1",
  "+91",
  "+81",
  "+65",
  "+49",
  "+33",
  "+39",
  "+31",
  "+41",
  "+44",
];

const Login = () => {
  const [contactMethod, setContactMethod] = useState("phone");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const autoLoginUserCalled = useRef(false);
  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    let timer;
    if (isOtpSent && otpTimer > 0) {
      timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [otpTimer, isOtpSent]);

  const handleOtpRequest = async () => {
    if (contactMethod === "phone") {
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      if (phoneNumber.length === 10) {
        try {
          if (otpTimer > 0) return;
          await hitBeApi("phone/user/otp", "POST", { phoneNumber: fullPhoneNumber, websiteName:"tryinterviewai" });
          setIsOtpSent(true);
          setOtpTimer(60);
          toast({
            title: "OTP sent.",
            description: "Please check your phone for the OTP.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error.",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Error.",
          description: "Please enter a valid 10-digit phone number.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      try {
        if (otpTimer > 0) return;
        await hitBeApi("email/user/otp", "POST", { email , websiteName:"tryinterviewai"});
        setIsOtpSent(true);
        setOtpTimer(60);
        toast({
          title: "OTP sent.",
          description: "Please check your email for the OTP.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleOtpVerification = async () => {
    if (otp) {
      try {
        const response =
          contactMethod === "phone"
            ? await hitBeApi("phone/user/otp/verify", "POST", {
                phoneNumber: `${countryCode}${phoneNumber}`,
                otp: parseInt(otp),
              })
            : await hitBeApi("email/user/otp/verify", "POST", {
                email,
                otp: parseInt(otp),
              });

        if (response.success) {
          document.cookie = `token=${response.token}; path=/;`;
          dispatch({
            type: "SET_EXAM_ID",
            value: response?.data?.otherDetails?.examId,
          });
          dispatch({
            type: "SET_ATTEMPT_ID",
            value: response?.data?.otherDetails?.attemptId,
          });
          toast({
            title: "OTP verified.",
            description: "Otp verified successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/dashboard");
          refreshPage();
        } else {
          toast({
            title: "Error",
            description: "Incorrect OTP.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Error.",
        description: "Please enter the OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPhoneNumberError("");
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";

    if (contactMethod === "phone" && phoneNumber.length !== 10) {
      setPhoneNumberError("Phone number must be exactly 10 digits.");
      return;
    }

    if (password) {
      try {
        const response =
          contactMethod === "phone"
            ? await hitBeApi("user/login", "POST", {
                phoneNumber: `${countryCode}${phoneNumber}`,
                password,
              })
            : await hitBeApi("user/login", "POST", {
                email,
                password,
              });

        if (response.success) {
          document.cookie = `token=${response.data.token}; path=/;`;
          dispatch({
            type: "SET_EXAM_ID",
            value: response?.data?.otherDetails?.examId,
          });
          dispatch({
            type: "SET_ATTEMPT_ID",
            value: response?.data?.otherDetails?.attemptId,
          });
          toast({
            title: "Logged in successfully.",
            description: "Welcome back!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/dashboard");
          refreshPage();
        } else {
          toast({
            title: "Error.",
            description: "Login failed",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
        if (error.message.includes("Please verify user first")) {
          handleOtpRequest();
        }
      }
    } else {
      toast({
        title: "Error.",
        description: "Please fill in all fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!autoLoginUserCalled.current) {
      if (!isCookieExpired("token")) {
        navigate("/dashboard");
        toast({
          title: "Auto-Login Success.",
          description: "Login successful with browser cache.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        autoLoginUserCalled.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const headingColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      minH="70vh"
      paddingTop={{ base: "25%", lg: "10%" }}
    >
      <Stack spacing={8} align="center">
        <Heading as="h1" size="2xl" mb={4} color={headingColor}>
          Login
        </Heading>
        <Box
          p={6}
          bg={boxBgColor}
          boxShadow="lg"
          borderRadius="md"
          maxW="400px"
          w="100%"
        >
          <Box my="20px" w="100%">
            <GoogleLoginComponent></GoogleLoginComponent>
          </Box>
          {!isOtpSent ? (
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="contactMethod">Login Method</FormLabel>
                  <Select
                    id="contactMethod"
                    value={contactMethod}
                    onChange={(e) => setContactMethod(e.target.value)}
                    color={textColor}
                  >
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                  </Select>
                </FormControl>

                {contactMethod === "phone" ? (
                  <>
                    <FormControl isRequired>
                      <FormLabel htmlFor="countryCode">Country Code</FormLabel>
                      <Select
                        id="countryCode"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        color={textColor}
                      >
                        {countryCodes.map((code) => (
                          <option key={code} value={code}>
                            {code}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                      <Input
                        id="phoneNumber"
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        color={textColor}
                        maxLength={10}
                        placeholder="Enter your phone number"
                      />
                      {phoneNumberError && (
                        <Text color="red.500">{phoneNumberError}</Text>
                      )}
                    </FormControl>
                  </>
                ) : (
                  <FormControl isRequired>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      color={textColor}
                      placeholder="Enter your email"
                    />
                  </FormControl>
                )}

                <FormControl isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    color={textColor}
                    placeholder="Enter your password"
                  />
                </FormControl>

                <Button type="submit" colorScheme="teal" w="full">
                  Login
                </Button>
              </Stack>
            </form>
          ) : (
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel htmlFor="otp">OTP</FormLabel>
                <Input
                  id="otp"
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  color={textColor}
                  placeholder="Enter the OTP"
                />
              </FormControl>

              <Button
                onClick={handleOtpVerification}
                colorScheme="teal"
                w="full"
              >
                Verify OTP
              </Button>

              <Text textAlign="center" color={textColor}>
                Didn't receive the OTP?{" "}
                {otpTimer > 0 ? (
                  <Text as="span" color="red.500">
                    {otpTimer}s
                  </Text>
                ) : (
                  <Button
                    variant="link"
                    colorScheme="teal"
                    onClick={handleOtpRequest}
                  >
                    Resend OTP
                  </Button>
                )}
              </Text>
            </Stack>
          )}
        </Box>
        <Text color={textColor}>
          New user?{" "}
          <Link to="/register">
            <Button variant="link" colorScheme="teal">
              Create an account
            </Button>
          </Link>
        </Text>
        <Text color={textColor}>
          Forgot password?{" "}
          <Link to="/forgot-password">
            <Button variant="link" colorScheme="teal">
              Set here
            </Button>
          </Link>
        </Text>
      </Stack>
    </Box>
  );
};

export default Login;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  Avatar,
  useToast,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { hitBeApi } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getCookieToken } from "../../utils/utils";
import { serverBaseUrl } from "../../constants/Constants";
import PaymentAction from "./PaymentAction";

const Profile = () => {
  const [name, setName] = useState(""); // Ensure initial state is a string
  const [email, setEmail] = useState(""); // Ensure initial state is a string
  const [phone, setPhone] = useState(""); // Ensure initial state is a string
  const [profilePic, setProfilePic] = useState(null); // Can be null initially
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const userFromState = useSelector((state) => state.user);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const dispatch = useDispatch();
  const userDetailsFetched = useRef(false);
  const employerDetailsFetched = useRef(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userDetailsFetched.current) return;
      userDetailsFetched.current = true;
      try {
        const response = await hitBeApi("user", "GET");

        if (response) {
          dispatch({
            type: "SET_USER_ID",
            value: response.data.userDetails._id,
          });
          dispatch({
            type: "SET_USER",
            value: response.data.userDetails,
          });
          setName(response.data.userDetails.name || "");
          setEmail(response.data.userDetails.email || "");
          setPhone(response.data.userDetails.phoneNumber || "");
          setProfilePic(response.data.userDetails.profilePicture || null);
        }
        toast({
          title: "User details retrieved successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const fetchEmployerData = async () => {
      if (employerDetailsFetched.current) return;
      employerDetailsFetched.current = true;
      try {
        const response = await hitBeApi("employer", "GET");

        if (response) {
          dispatch({
            type: "SET_EMPLOYER_ID",
            value: response.data._id,
          });
          dispatch({
            type: "SET_EMPLOYER",
            value: response.data,
          });
          toast({
            title: "Employer details retrieved successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchUserData();
    fetchEmployerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userFromState && Object.keys(userFromState).length !== 0) {
      setName(userFromState.name || "");
      setEmail(userFromState.email || "");
      setPhone(userFromState.phoneNumber || "");
      setProfilePic(userFromState.profilePicture || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfilePicChange = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setIsSubmitDisabled(true);

    try {
      setLoading(true);
      const token = getCookieToken();
      const response = await axios.post(
        `${serverBaseUrl}/file/upload/aws`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );
      setProfilePic(response?.data?.data?.fileUrl);
      toast({
        title: "Profile picture uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitDisabled(false);
    } catch (error) {
      toast({
        title: "Error uploading profile picture.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitDisabled) {
      return;
    }
    try {
      const response = await hitBeApi("user", "PATCH", {
        name,
        email,
        phone,
        profilePic,
      });

      dispatch({
        type: "SET_USER",
        value: response.data.updatedUserDetails,
      });

      setName(response.data.updatedUserDetails.name || "");
      setEmail(response.data.updatedUserDetails.email || "");
      setPhone(response.data.updatedUserDetails.phoneNumber || "");
      setProfilePic(response.data.updatedUserDetails.profilePicture || null);
      toast({
        title: "User details updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const boxBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const headingColor = useColorModeValue("gray.700", "white");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const tokenBg = useColorModeValue("gray.50", "gray.700");

  return (
    <Box
      bg={boxBg}
      py={8}
      px={6}
      minH="100vh"
      maxW={{ base: "95%", md: "75%", lg: "50%" }}
      mx="auto"
      mt={{ base: "4%", lg: "8%" }}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      boxShadow="md"
    >
      <Heading as="h2" size="lg" mb={4} textAlign="center" color={headingColor}>
        Profile
      </Heading>

      <Stack spacing={6} alignItems="center">
        <Box w="full" textAlign="center">
          <PaymentAction />
        </Box>

        {userFromState?.userTokenDetails && (
          <Box
            mt={6}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            bg={tokenBg}
            textAlign="center"
          >
            <Text fontWeight="bold" fontSize="lg" color={textColor}>
              AI Credits Used: {userFromState.userTokenDetails.totalTokensUsed}{" "}
              / {userFromState.userTokenDetails.tokenLimit}
            </Text>
          </Box>
        )}

        <Stack as="form" onSubmit={handleSubmit} spacing={5} w="full">
          <FormControl isRequired>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </FormControl>

          <FormControl isDisabled>
            <FormLabel htmlFor="phone">Phone</FormLabel>
            <Input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone number"
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="profile-pic">Profile Picture</FormLabel>
            <Input
              id="profile-pic"
              type="file"
              accept="image/*"
              onChange={handleProfilePicChange}
            />
            {loading && <Spinner size="sm" />}
            {profilePic && (
              <Avatar
                src={profilePic}
                name={name}
                size="xl"
                mt={4}
                transition="transform 0.2s"
                _hover={{ transform: "scale(1.2)" }}
              />
            )}
          </FormControl>

          <Box textAlign="center" w="full">
            <Button type="submit" colorScheme="blue" size="lg" mt={4} w="full">
              {isSubmitDisabled ? <Spinner size="sm" /> : "Save"}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  Heading,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Stack,
  Divider,
} from "@chakra-ui/react";
import { hitBeApi } from "../../../api/api";

const AssessmentAttempts = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [answerDetails, setAnswerDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        setLoading(true);
        const response = await hitBeApi("assessment/active", "GET", null);
        const completedAssessments = response?.data.filter(
          (assessment) => assessment.answerCount > 0
        );
        setAssessments(completedAssessments);
      } catch (error) {
        console.error("Error fetching assessments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, []);

  const handleViewDetails = async (assessmentId) => {
    setLoadingDetails(true);
    try {
      const response = await hitBeApi(
        `assessment/${assessmentId}/answers`,
        "GET",
        null
      );
      setAnswerDetails(response.data);
    } catch (error) {
      console.error("Error fetching answers:", error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const tableBg = useColorModeValue("white", "gray.800");

  return (
    <Box p={6}>
      <Heading size="lg" mb={4}>
        Assessment Attempts
      </Heading>
      {loading ? (
        <VStack spacing={4} align="center" justify="center" h="50vh">
          <Spinner size="xl" />
          <Text>Loading assessments...</Text>
        </VStack>
      ) : (
        <Box bg={tableBg} rounded="md" shadow="md" p={4}>
          <Box overflowX="auto">
            <Table variant="striped" colorScheme="teal" size="sm">
              <Thead>
                <Tr>
                  <Th>Title</Th>
                  <Th>Description</Th>
                  <Th>Total Attempts Count</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {assessments.map((assessment) => (
                  <Tr key={assessment._id}>
                    <Td>{assessment.title}</Td>
                    <Td>{assessment.description}</Td>
                    <Td>{assessment.answerCount}</Td>
                    <Td>
                      <Button
                        onClick={() => handleViewDetails(assessment._id)}
                        colorScheme="blue"
                        size="sm"
                      >
                        View Details
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      )}

      {loadingDetails ? (
        <VStack spacing={4} align="center" justify="center" mt={8}>
          <Spinner size="lg" />
          <Text>Loading answer details...</Text>
        </VStack>
      ) : (
        answerDetails && (
          <Box mt={8}>
            <Heading size="md" mb={4}>
              Answer Details
            </Heading>
            <VStack spacing={4} align="stretch">
              {Object.entries(answerDetails).map(
                ([userId, answersArray], userIdx) => (
                  <Box
                    key={userId}
                    p={{base:1,lg:4}}
                    bg={userIdx % 2 === 0 ? "blue.50" : "green.50"}
                    rounded="md"
                    shadow="md"
                  >
                    <Heading size="sm" mb={4}>
                      User: {answersArray[0]?.userId?.name} (
                      {answersArray[0]?.userId?.email})
                    </Heading>
                    <Divider mb={4} />
                    {answersArray.map((answerEntity, idx) => {
                      // Calculate total marks for this attempt
                      const totalMarks = answerEntity.answers.reduce(
                        (sum, ans) => sum + ans.marksAwarded,
                        0
                      );
                      const maxMarks = answerEntity.answers.reduce(
                        (sum, ans) => sum + ans.questionId?.marks,
                        0
                      );

                      return (
                        <Card
                          key={answerEntity._id}
                          bg={idx % 2 === 0 ? "gray.100" : "gray.200"}
                          rounded="lg"
                          shadow="md"
                          p={{ base: 1, lg: 4 }}
                          mb={4}
                        >
                          <CardHeader>
                            <HStack justify="space-between">
                              <Text
                                fontWeight="bold"
                                fontSize={{ base: "sm", lg: "lg" }}
                              >
                                Attempt {idx + 1}
                              </Text>
                              <Badge
                                colorScheme={
                                  answerEntity.answers.some((a) => a.isCorrect)
                                    ? "green"
                                    : "red"
                                }
                              >
                                {answerEntity.answers.some((a) => a.isCorrect)
                                  ? "Contains Correct Answers"
                                  : "No Correct Answers"}
                              </Badge>
                            </HStack>
                            <Text fontSize="sm" mt={2}>
                              <strong>Total Marks:</strong> {totalMarks}/
                              {maxMarks}
                            </Text>
                          </CardHeader>
                          <Divider />
                          <CardBody>
                            <Stack spacing={3}>
                              {answerEntity.answers.map((ans, ansIdx) => (
                                <Box
                                  key={ans._id}
                                  bg="white"
                                  p={3}
                                  rounded="md"
                                  shadow="sm"
                                >
                                  <Text>
                                    <strong>Q{ansIdx + 1}:</strong>{" "}
                                    {ans.questionId?.questionText}
                                  </Text>
                                  <Text>
                                    <strong>Answer:</strong> {ans.answer}
                                  </Text>
                                  <Badge
                                    colorScheme={
                                      ans.isCorrect ? "green" : "red"
                                    }
                                  >
                                    {ans.isCorrect ? "Correct" : "Incorrect"}
                                  </Badge>
                                  <Text>
                                    <strong>Marks Awarded:</strong>{" "}
                                    {ans.marksAwarded}/{ans.questionId?.marks}
                                  </Text>
                                </Box>
                              ))}
                            </Stack>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </Box>
                )
              )}
            </VStack>
          </Box>
        )
      )}
    </Box>
  );
};

export default AssessmentAttempts;

import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Select,
  VStack,
  HStack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { hitBeApi } from "../../api/api";
import { useNavigate } from "react-router-dom";

const countryCodes = [
  "+1",
  "+91",
  "+81",
  "+65",
  "+49",
  "+33",
  "+39",
  "+31",
  "+41",
  "+44",
];

const EmployerForgotPassword = () => {
  const [contactMethod, setContactMethod] = useState("phone");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const backgroundColor = useColorModeValue("gray.50", "gray.800");
  const buttonColor = useColorModeValue("blue.500", "blue.300");
  const buttonHoverColor = useColorModeValue("blue.600", "blue.400");

  const handleSendOtp = async () => {
    setIsLoading(true);
    try {
      if (contactMethod === "phone") {
        const fullPhoneNumber = `${countryCode}${phoneNumber}`;
        await hitBeApi("phone/employer/otp", "POST", { phoneNumber: fullPhoneNumber ,websiteName:"tryinterviewai"});
      } else {
        await hitBeApi("email/employer/otp", "POST", { email , websiteName:"tryinterviewai"});
      }
      setStep(2);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true);
    try {
      const response =
        contactMethod === "phone"
          ? await hitBeApi("phone/employer/otp/verify", "POST", {
              phoneNumber: `${countryCode}${phoneNumber}`,
              otp: parseInt(otp),
            })
          : await hitBeApi("email/employer/otp/verify", "POST", {
              email,
              otp: parseInt(otp),
            });

      if (response.success) {
        document.cookie = `token=${response?.data?.token}; path=/;`;
        setStep(3);
      } else {
        toast({
          title: "Error",
          description: "Invalid Otp, please fill correct otp",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "OTP verification failed. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      if (password !== confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const response = await hitBeApi("employer/forgot-password", "POST", {
        password,
      });

      if (response.success) {
        toast({
          title: "Success",
          description:
            "Password reset successfully. You are being auto-logged in.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/login");
      } else {
        toast({
          title: "Error",
          description: "Failed to reset password. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.message || "Failed to reset password. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      bg={backgroundColor}
      py={20}
      px={5}
      mx={"auto"}
      borderRadius="md"
      my="10%"
      boxShadow="lg"
      maxW={{ base: "90%", md: "50%", lg: "30%" }}
    >
      <VStack spacing={4} align="stretch">
        {step === 1 && (
          <>
            <Text>Select method to reset your password:</Text>
            <Select
              value={contactMethod}
              onChange={(e) => setContactMethod(e.target.value)}
            >
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </Select>
            {contactMethod === "email" ? (
              <Input
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            ) : (
              <HStack>
                <Select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  w="40%"
                >
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Select>
                <Input
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  w="60%"
                />
              </HStack>
            )}
            <Button
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
              color="white"
              onClick={handleSendOtp}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Continue
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Text>
              Please verify otp sent over your 
              {contactMethod === "email"
                ? `email ${email}`
                : `phoneNumber ${phoneNumber}`}
              :
            </Text>
            <Input
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <Button
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
              color="white"
              onClick={handleVerifyOtp}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Verify OTP
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <Text>Select set a new minimum 6 digit password:</Text>
            <Input
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              placeholder="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
              color="white"
              onClick={handleResetPassword}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Reset Password
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default EmployerForgotPassword;

import React, { useState } from "react";
import {
  Text,
  VStack,
  Heading,
  Box,
  Grid,
} from "@chakra-ui/react";
import InterviewAssessmentStart from "./InterviewAssessmentStart";
import AiInterviewAvatar from "./AiInterviewAvatar";
const InterviewAssessment = () => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [startInterview, setStartInterview] = useState(false);

  const avatars = [
    {
      id: 1,
      videoPath: "/interviews/videos/normal-face-men1.mp4",
      name: "Alice",
    },
    {
      id: 2,
      videoPath: "/interviews/videos/normal-face-men2.mp4",
      name: "Elok",
    },
    {
      id: 3,
      videoPath: "/interviews/videos/normal-face-men3.mp4",
      name: "Jest",
    },
    {
      id: 4,
      videoPath: "/interviews/videos/normal-face-men4.mp4",
      name: "Nick",
    },
    {
      id: 5,
      videoPath: "/interviews/videos/normal-face-women1.mp4",
      name: "Sophie",
    },
    {
      id: 6,
      videoPath: "/interviews/videos/normal-face-women2.mp4",
      name: "Ken",
    },
  ];

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar)
    setStartInterview(true)
  };


  if (startInterview) {
    return (
      <InterviewAssessmentStart
        avatar={selectedAvatar}
      />
    );
  }

  return (
    <VStack
      spacing={8}
      p={6}
      align="center"
      bg="gray.50"
      borderRadius="md"
      boxShadow="lg"
      maxW="80%"
      mx="auto"
      mt={8}
    >
      <Heading as="h1" size="xl" color="teal.600">
        AI Mock Interview
      </Heading>

      
        <>
          <Heading as="h2" size="md" color="gray.700">
            Choose an AI Avatar
          </Heading>
          <Box mt={4}>
            <Grid
              templateColumns="repeat(2, 1fr)" // 2 avatars per row
              gap={6} // Gap between grid items
              justifyContent="center"
            >
              {avatars.map((avatar) => (
                <Box
                  key={avatar.id}
                  borderWidth={2}
                  borderColor={
                    selectedAvatar?.id === avatar.id ? "teal.500" : "gray.300"
                  }
                  borderRadius="lg"
                  p={4}
                  textAlign="center"
                  cursor="pointer"
                  boxShadow={
                    selectedAvatar?.id === avatar.id ? "0 0 10px teal" : "sm"
                  }
                  _hover={{ transform: "scale(1.05)", transition: "0.3s" }}
                  onClick={() => handleAvatarSelect(avatar)}
                >
                  <AiInterviewAvatar
                    isSpeaking={false}
                    neutralVideoSrc={avatar.videoPath}
                  />
                  <Text fontWeight="bold" color="gray.800" mt={2}>
                    {avatar.name}
                  </Text>
                </Box>
              ))}
            </Grid>
          </Box>
        </>
      
    </VStack>
  );
};

export default InterviewAssessment;

import React from "react";
import { Box, Heading, Text, Button, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <Box
      position="relative"
      bgImage="https://images.pexels.com/photos/4492126/pexels-photo-4492126.jpeg"
      bgRepeat="no-repeat"
      bgSize="cover"
      color="white"
      py="20%"
      px={8}
      textAlign="center"
    >
      {/* Semi-transparent overlay */}
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)" // Adjust the opacity as needed
        zIndex={1}
      />
      <VStack spacing={6} position="relative" zIndex={2}>
        <Heading as="h1" size="2xl" fontWeight="bold">
          Welcome to TryInterview AI
        </Heading>
        <Text fontSize="xl">
          The AI-powered platform for aspiring job seekers and employers.
        </Text>
        <Text fontSize="lg" maxW="2xl">
          Practice realistic mock job interviews with AI, analyze your
          performance with advanced analytics, and improve your skills.
          Employers can create custom interviews or use our question pool to
          screen candidates effectively.
        </Text>
        <Button colorScheme="teal" size={{ base: "md", lg: "lg" }} mt={4}>
          <Link to="/login">
            Get Started with TryInterview AI (as Candidate)
          </Link>
        </Button>
        <Button colorScheme="teal" size={{ base: "md", lg: "lg" }} mt={4}>
          <Link to="/employer/login">
            Get Started with TryInterview AI (as Employer)
          </Link>
        </Button>
      </VStack>
    </Box>
  );
};

export default HeroSection;

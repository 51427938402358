import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  Button,
  Spinner,
  useToast,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Table,
  Td,
  Tr,
  Thead,
  Th,
  Tbody,
} from "@chakra-ui/react";
import InterviewDetails from "./InterviewDetails"; // Component for rendering charts
import { hitBeApi } from "../../../api/api";
import { useSelector } from "react-redux";

const CompletedInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [filteredInterviews, setFilteredInterviews] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("title");
  const [filterValue, setFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewingInterview, setViewingInterview] = useState(null);
  const userFromState = useSelector((state) => state.user);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(userFromState ? userFromState?.isFeedbackSubmitted : false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    question1: "",
    question2: "",
    question3: "",
  });
  const toast = useToast();

  useEffect(() => {
    fetchInterviews();

    if (userFromState && userFromState?.isFeedbackSubmitted) {
      setFeedbackModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFromState]);

  const handleFeedbackSubmit = async () => {
    try {
      if (feedbackData?.question1?.length === 0) {
        toast({
          title: "Submission Failed",
          description: "Please answer question 1.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (feedbackData?.question2?.length === 0) {
        toast({
          title: "Submission Failed",
          description: "Please answer question 2.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setIsSubmittingFeedback(true);
      const response = await hitBeApi(
        "interview/feedback",
        "POST",
        feedbackData
      );
      setIsSubmittingFeedback(false);

      if (response.success) {
        toast({
          title: "Feedback Submitted",
          description: "Thank you for your feedback!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setFeedbackModalOpen(false);
      } else {
        toast({
          title: "Submission Failed",
          description: response.message || "Failed to submit feedback.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({ ...feedbackData, [name]: value });
  };

  const fetchInterviews = async () => {
    setLoading(true);
    try {
      const response = await hitBeApi(`interview`, "GET");

      if (response.success && response.data?.data) {
        const sortedInterviews = response.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setInterviews(sortedInterviews);
        setFilteredInterviews(sortedInterviews);

        toast({
          title: "Interviews Loaded",
          description: "Interviews were successfully fetched.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Fetch Failed",
          description: response.message || "Failed to fetch interviews.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
    if (!e.target.value) {
      setFilteredInterviews(interviews);
      return;
    }
    const filtered = interviews.filter((interview) =>
      interview[selectedColumn]
        ?.toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    setFilteredInterviews(filtered);
  };

  const handleViewDetails = async (interviewId) => {
    setViewingInterview("loading");

    try {
      const response = await hitBeApi(`interview/${interviewId}`, "GET");

      if (response.success && response.data) {
        setViewingInterview(response.data);

        toast({
          title: "Interview Details Loaded",
          description: "Interview details were successfully fetched.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Fetch Failed",
          description: response.message || "Failed to fetch interview details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        setViewingInterview(null);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      setViewingInterview(null);
    }
  };

  const handleBackToSummaries = () => {
    setViewingInterview(null);
  };

  if (loading) {
    return (
      <Box textAlign="center" mt="4">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p="4">
      {viewingInterview ? (
        <Box>
          {viewingInterview === "loading" ? (
            <Box textAlign="center" mt="4">
              <Spinner size="xl" />
            </Box>
          ) : (
            <Box>
              <Button onClick={handleBackToSummaries} colorScheme="blue" mb={4}>
                Back to All Summaries
              </Button>
              <InterviewDetails data={viewingInterview} />
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box mb="4">
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
              mb="2"
            >
              <option value="domain">Domain</option>
              <option value="role">Role</option>
              <option value="aiAvatarName">AI Avatar</option>
            </Select>
            <input
              type="text"
              placeholder={`Filter by ${selectedColumn}`}
              value={filterValue}
              onChange={handleFilterChange}
              style={{ padding: "8px", width: "100%" }}
            />
          </Box>

          <VStack spacing={4} align="stretch">
            <Table
              variant="simple"
              size="md"
              bg="white"
              borderRadius="lg"
              boxShadow="md"
            >
              <Thead bg="gray.100">
                <Tr>
                  <Th>Role & Domain</Th>

                  <Th>AI Avatar</Th>
                  <Th>Created At</Th>
                  <Th>Sections</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredInterviews.map((interview) => (
                  <Tr key={interview._id} _hover={{ bg: "gray.50" }}>
                    <Td>
                      <Text fontSize="sm" color="gray.500">
                        {interview.role} - {interview.domain}
                      </Text>
                    </Td>

                    <Td>
                      <Text color="gray.800">{interview.aiAvatarName}</Text>
                    </Td>
                    <Td>
                      <Text color="gray.800">
                        {new Date(interview.createdAt)
                          .toLocaleString("en-US", {
                            timeZone: "UTC",
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace(/(\d{1,2})/, (day) => `${day}th`)}
                      </Text>
                    </Td>
                    <Td>
                      <Box color="gray.800">
                        {interview.sections.map((section) => (
                          <Text key={section._id}>
                            • {section.title} - {section.questionIds.length}{" "}
                            questions
                          </Text>
                        ))}
                      </Box>
                    </Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        size="sm"
                        onClick={() => handleViewDetails(interview._id)}
                      >
                        View Details
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </VStack>
        </Box>
      )}
      <Box>
        <Modal
          isOpen={isFeedbackModalOpen}
          onClose={() => setFeedbackModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              We Value Your Feedback (can you please share)
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Text>
                  1. Are there any features you think we could improve or add?
                </Text>
                <Textarea
                  name="question1"
                  placeholder="Your answer"
                  value={feedbackData.question1}
                  onChange={handleFeedbackChange}
                />

                <Text>
                  2. Did you encounter any challenges or have any suggestions
                  for improvement?
                </Text>
                <Textarea
                  name="question2"
                  placeholder="Your answer"
                  value={feedbackData.question2}
                  onChange={handleFeedbackChange}
                />

                <Text>
                  3. Are you willing to pay us money for this product after we
                  incorporate all of your improvements/suggestions in our
                  product ?
                </Text>
                <Textarea
                  name="question3"
                  placeholder="Your answer"
                  value={feedbackData.question3}
                  onChange={handleFeedbackChange}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleFeedbackSubmit}
                isDisabled={isSubmittingFeedback}
              >
                Submit
              </Button>
              <Button
                variant="ghost"
                onClick={() => setFeedbackModalOpen(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default CompletedInterviews;

import React, { useEffect, useState } from "react";
import {
  useToast,
  Button,
  Input,
  Textarea,
  Select,
  Stack,
  FormControl,
  FormLabel,
  Heading,
  Box,
  VStack,
  HStack,
  Text,
  IconButton,
  Divider,
  SimpleGrid,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalFooter,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  AddIcon,
  CheckCircleIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { hitBeApi } from "../../../api/api";
import { FiShare2 } from "react-icons/fi";
import AssessmentEditForm from "./AssessmentEditForm";

const ManageAssessments = () => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isCreatingAssessment, setIsCreatingAssessment] = useState(false);

  // State variables
  const [assessments, setAssessments] = useState([]);
  const [currentAssessment, setCurrentAssessment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [assessmentTitle, setAssessmentTitle] = useState("");
  const [assessmentDescription, setAssessmentDescription] = useState("");

  // Question form states
  const [questionText, setQuestionText] = useState("");
  const [questionType, setQuestionType] = useState("subjective");
  const [options, setOptions] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [marks, setMarks] = useState("");

  // eslint-disable-next-line
  useEffect(() => {
    handleFetchAssessments();
  }, []);

  // Fetch all assessments
  const handleFetchAssessments = async () => {
    try {
      const response = await hitBeApi("assessment", "GET", null);
      setAssessments(response.data);

      if (currentAssessment && currentAssessment._id) {
        const matchingAssessment = response.data.find(
          (assessment) => assessment._id === currentAssessment._id
        );

        if (matchingAssessment) {
          setCurrentAssessment(matchingAssessment);
        }
      }
    } catch (error) {
      toast({
        title: "Error fetching assessments",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleCreateAssessment = async () => {
    try {
      setIsCreatingAssessment(true);
      await hitBeApi("assessment", "POST", {
        title: assessmentTitle,
        description: assessmentDescription,
      });
      setIsCreatingAssessment(false);
      await handleFetchAssessments();
      toast({
        title: "Assessment Created",
        description: "Your assessment has been created successfully.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      setIsCreatingAssessment(false);
      toast({
        title: "Error Creating Assessment",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  // Fetch current assessment details
  const handleFetchCurrentAssessment = async (assessmentId) => {
    try {
      const response = await hitBeApi(
        `assessment/${assessmentId}`,
        "GET",
        null
      );
      setCurrentAssessment(response.data);
      setQuestions(response.data.questions || []);
      setIsModalOpen(true);
    } catch (error) {
      toast({
        title: "Error fetching current assessment",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateAssessment = async ({ newStatus, title, description }) => {
    if (!currentAssessment) return;

    try {
      await hitBeApi(`assessment/${currentAssessment._id}`, "PATCH", {
        status: newStatus,
        title,
        description,
      });

      // Refetch assessments to update the list
      await handleFetchAssessments();

      toast({
        title: `Assessment Updated`,
        description: `The assessment is updated now.`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error Changing Status",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  // Add a new question to the current assessment
  const handleAddQuestion = async () => {
    if (!currentAssessment) return;

    try {
      const questionData = {
        assessmentId: currentAssessment._id,
        questionText,
        questionType,
        options: questionType === "objective" ? options.split(",") : [],
        correctAnswer,
        marks,
      };

      await hitBeApi("assessment/question", "POST", questionData);

      // Refetch current assessment to get updated questions
      await handleFetchCurrentAssessment(currentAssessment._id);

      toast({
        title: "Question Added",
        description: "Your question has been added successfully.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      // Reset question form
      resetQuestionForm();
    } catch (error) {
      toast({
        title: "Error Adding Question",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  // Delete a question from the current assessment
  const handleDeleteQuestion = async (questionId) => {
    if (!currentAssessment) return;

    try {
      await hitBeApi(
        `assessment/${currentAssessment._id}/questions/${questionId}`,
        "DELETE"
      );

      // Refetch current assessment to get updated questions
      await handleFetchCurrentAssessment(currentAssessment._id);

      await handleFetchAssessments();

      toast({
        title: "Question Deleted",
        description: "The question has been deleted successfully.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error Deleting Question",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  // Reset question form fields
  const resetQuestionForm = () => {
    setQuestionText("");
    setQuestionType("objective");
    setOptions("");
    setCorrectAnswer("");
    setMarks("");
  };

  // Group assessments by status
  const groupedAssessments = {
    created: assessments.filter(
      (assessment) => assessment.status === "created"
    ),
    draft: assessments.filter((assessment) => assessment.status === "draft"),
    completed: assessments.filter(
      (assessment) => assessment.status === "completed"
    ),
  };

  return (
    <Stack
      spacing={8}
      maxW="1000px"
      py={8}
      mx="auto"
      px={{ base: "5%", lg: "0%" }}
    >
      <Heading textAlign="center" size="lg" mb={4}>
        Manage Assessments
      </Heading>

      <>
        {/* Floating '+' Icon */}
        <IconButton
          icon={<AddIcon />}
          onClick={onOpen}
          size="lg"
          colorScheme="teal"
          borderRadius="full"
          aria-label="Create New Assessment"
          boxShadow="lg"
          _hover={{ transform: "scale(1.1)", bg: "teal.600" }}
        />

        {/* Dialog Box */}
        <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
          <ModalOverlay bg="blackAlpha.600" />
          <ModalContent rounded="lg" shadow="2xl">
            {/* Modal Header */}
            <ModalHeader
              textAlign="center"
              bgGradient="linear(to-r, teal.400, teal.600)"
              color="white"
              py={4}
              roundedTop="lg"
              fontSize="xl"
              fontWeight="bold"
            >
              Create New Assessment
            </ModalHeader>

            {/* Modal Body */}
            <ModalBody bg="gray.50" py={6}>
              <VStack spacing={6}>
                {/* Assessment Title */}
                <FormControl isRequired>
                  <FormLabel fontWeight="bold" color="teal.700">
                    Assessment Title
                  </FormLabel>
                  <Input
                    value={assessmentTitle}
                    onChange={(e) => setAssessmentTitle(e.target.value)}
                    placeholder="Enter assessment title"
                    focusBorderColor="teal.500"
                    _hover={{ boxShadow: "md" }}
                    _focus={{ boxShadow: "lg" }}
                  />
                </FormControl>

                {/* Assessment Description */}
                <FormControl isRequired>
                  <FormLabel fontWeight="bold" color="teal.700">
                    Assessment Description
                  </FormLabel>
                  <Textarea
                    value={assessmentDescription}
                    onChange={(e) => setAssessmentDescription(e.target.value)}
                    placeholder="Enter assessment description"
                    focusBorderColor="teal.500"
                    _hover={{ boxShadow: "md" }}
                    _focus={{ boxShadow: "lg" }}
                  />
                </FormControl>
              </VStack>
            </ModalBody>

            {/* Modal Footer */}
            <ModalFooter bg="gray.100" roundedBottom="lg">
              <Button
                colorScheme="teal"
                onClick={handleCreateAssessment}
                isDisabled={
                  !assessmentTitle ||
                  !assessmentDescription ||
                  isCreatingAssessment
                }
                size="lg"
                rounded="lg"
                _hover={{ transform: "scale(1.05)" }}
              >
                Create Assessment
              </Button>
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={onClose}
                ml={3}
                size="lg"
                rounded="lg"
                _hover={{ bg: "gray.200" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>

      {/* Assessments Display */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} py={4}>
        {/* Created Assessments */}
        <Box bg="blue.50" p={5} borderRadius="lg" boxShadow="lg">
          <Heading
            size="md"
            color="blue.600"
            mb={3}
            display="flex"
            alignItems="center"
          >
            <Icon as={CheckCircleIcon} color="blue.500" boxSize={5} mr={2} />
            Created
          </Heading>
          <Divider mb={4} />
          {groupedAssessments.created.length > 0 ? (
            <Stack spacing={4}>
              {groupedAssessments.created.map((assessment) => (
                <Box
                  key={assessment._id}
                  p={4}
                  borderRadius="md"
                  bg="white"
                  boxShadow="sm"
                  borderWidth={1}
                  _hover={{
                    boxShadow: "md",
                    transform: "scale(1.02)",
                    transition: "0.2s",
                  }}
                  cursor="pointer"
                  onClick={() => handleFetchCurrentAssessment(assessment._id)}
                >
                  <Heading size="sm" mb={1} color="gray.800">
                    Title: {assessment.title}
                  </Heading>
                  <Text fontSize="sm" color="gray.600" mb={2}>
                    Desc: {assessment.description}
                  </Text>
                  <Badge colorScheme="blue">
                    Questions Count: {assessment?.questions?.length}
                  </Badge>
                </Box>
              ))}
            </Stack>
          ) : (
            <Text color="gray.600" fontSize="sm">
              No assessments in "Created" status
            </Text>
          )}
        </Box>

        {/* Draft Assessments */}
        <Box bg="yellow.50" p={5} borderRadius="lg" boxShadow="lg">
          <Heading
            size="md"
            color="yellow.600"
            mb={3}
            display="flex"
            alignItems="center"
          >
            <Icon as={EditIcon} color="yellow.500" boxSize={5} mr={2} />
            Draft
          </Heading>
          <Divider mb={4} />
          {groupedAssessments.draft.length > 0 ? (
            <Stack spacing={4}>
              {groupedAssessments.draft.map((assessment) => (
                <Box
                  key={assessment._id}
                  p={4}
                  borderRadius="md"
                  bg="white"
                  boxShadow="sm"
                  borderWidth={1}
                  _hover={{
                    boxShadow: "md",
                    transform: "scale(1.02)",
                    transition: "0.2s",
                  }}
                  cursor="pointer"
                  onClick={() => handleFetchCurrentAssessment(assessment._id)}
                >
                  <Heading size="sm" mb={1} color="gray.800">
                    Title: {assessment.title}
                  </Heading>
                  <Text fontSize="sm" color="gray.600" mb={2}>
                    Desc: {assessment.description}
                  </Text>
                  <Badge colorScheme="yellow">
                    Questions Count: {assessment?.questions?.length}
                  </Badge>
                </Box>
              ))}
            </Stack>
          ) : (
            <Text color="gray.600" fontSize="sm">
              No assessments in "Draft" status
            </Text>
          )}
        </Box>

        {/* Completed Assessments */}
        <Box bg="green.50" p={5} borderRadius="lg" boxShadow="lg">
          <Heading
            size="md"
            color="green.600"
            mb={3}
            display="flex"
            alignItems="center"
          >
            <Icon as={CheckCircleIcon} color="green.500" boxSize={5} mr={2} />
            Completed
          </Heading>
          <Divider mb={4} />
          {groupedAssessments.completed.length > 0 ? (
            <Stack spacing={4}>
              {groupedAssessments.completed.map((assessment) => (
                <Box
                  key={assessment._id}
                  p={4}
                  borderRadius="md"
                  bg="white"
                  boxShadow="sm"
                  borderWidth={1}
                  _hover={{
                    boxShadow: "md",
                    transform: "scale(1.02)",
                    transition: "0.2s",
                  }}
                  cursor="pointer"
                  onClick={() => handleFetchCurrentAssessment(assessment._id)}
                >
                  <Heading size="sm" mb={1} color="gray.800">
                    Title: {assessment.title}
                  </Heading>
                  <Text fontSize="sm" color="gray.600" mb={2}>
                    Desc: {assessment.description}
                  </Text>
                  <Badge colorScheme="green" mb={2}>
                    Questions Count: {assessment?.questions?.length}
                  </Badge>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Text fontSize="xs" color="gray.500">
                        Last updated:{" "}
                        {new Date(assessment.updatedAt)
                          .toLocaleString("en-US", {
                            timeZone: "UTC",
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })
                          .replace(/(\d{1,2})/, (day) => `${day}th`)}
                      </Text>
                    </Box>
                    <Box
                      as="button"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering box click
                        const shareLink = `https://tryinterviewai.com/dashboard/ai-interview/${assessment._id}`;
                        navigator.clipboard.writeText(shareLink);
                        alert(`Link copied to clipboard: ${shareLink}`);
                      }}
                    >
                      <Icon as={FiShare2} color="blue.500" boxSize={5} />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          ) : (
            <Text color="gray.600" fontSize="sm">
              No assessments in "Completed" status
            </Text>
          )}
        </Box>
      </SimpleGrid>

      {/* Assessment Details Modal */}

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          handleFetchAssessments();
        }}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.500" color="white" py={4}>
            <Heading size="lg">Edit Assessment</Heading>
          </ModalHeader>
          <ModalCloseButton color="white" />

          <ModalBody maxH="70vh" overflowY="auto" p={6}>
            {/* Editable Form Section */}
            <Box mb={8}>
              <Heading size="md" mb={4} display="flex" alignItems="center">
                <Box as="span" mr={2} color="blue.500">
                  <Icon as={EditIcon} />
                </Box>
                Edit Assessment Details
              </Heading>
              <AssessmentEditForm
                currentAssessment={currentAssessment}
                onSave={(updatedFields) =>
                  updateAssessment({ ...updatedFields })
                }
              />
            </Box>

            <Divider />

            {/* Status Change Section */}
            <Box mb={8}>
              <Heading size="md" mb={4} display="flex" alignItems="center" mt={8}>
                <Box as="span" mr={2} color="blue.500">
                  <Icon as={EditIcon} />
                </Box>
                Change Assessment Status
              </Heading>
              <HStack justify="center" spacing={4}>
                <Button
                  colorScheme="blue"
                  variant="solid"
                  rounded="full"
                  onClick={() => updateAssessment({ newStatus: "draft" })}
                  isDisabled={currentAssessment?.status === "draft"}
                  _hover={{ bg: "blue.600" }}
                >
                  Set as Draft
                </Button>
                <Button
                  colorScheme="green"
                  variant="solid"
                  rounded="full"
                  onClick={() => updateAssessment({ newStatus: "completed" })}
                  isDisabled={
                    currentAssessment?.status === "completed" ||
                    currentAssessment?.status === "created"
                  }
                  _hover={{ bg: "green.600" }}
                >
                  Mark as Completed
                </Button>
              </HStack>
            </Box>

            <Divider />

            <Divider />

            {/* Add Question Section */}
            <Box
              mb={8}
              py={6}
            >
              <Box
                bgGradient="linear(to-r, teal.400, teal.600)"
                color="white"
                roundedTop="lg"
                py={4}
                px={6}
                display="flex"
                alignItems="center"
              >
                <Icon as={AddIcon} boxSize={6} mr={3} />
                <Heading size="md">Add Question</Heading>
              </Box>

              <Box
                bg="white"
                shadow="md"
                roundedBottom="lg"
                p={6}
                borderTop="4px solid"
                borderColor="teal.400"
              >
                <VStack spacing={6} align="stretch">
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" color="teal.600">
                      Question Text
                    </FormLabel>
                    <Input
                      value={questionText}
                      onChange={(e) => setQuestionText(e.target.value)}
                      placeholder="Enter question text"
                      focusBorderColor="teal.500"
                      _hover={{ boxShadow: "md" }}
                      _focus={{ boxShadow: "lg" }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" color="teal.600">
                      Question Type
                    </FormLabel>
                    <Select
                      value={questionType}
                      onChange={(e) => setQuestionType(e.target.value)}
                      focusBorderColor="teal.500"
                      _hover={{ boxShadow: "md" }}
                      _focus={{ boxShadow: "lg" }}
                    >
                      <option value="objective">Objective</option>
                      <option value="subjective">Subjective</option>
                      <option value="numerical">Numerical</option>
                    </Select>
                  </FormControl>

                  {questionType === "objective" && (
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" color="teal.600">
                        Options (Comma Separated)
                      </FormLabel>
                      <Input
                        value={options}
                        onChange={(e) => setOptions(e.target.value)}
                        placeholder="Enter options"
                        focusBorderColor="teal.500"
                        _hover={{ boxShadow: "md" }}
                        _focus={{ boxShadow: "lg" }}
                      />
                    </FormControl>
                  )}

                  <FormControl>
                    <FormLabel fontWeight="bold" color="teal.600">
                      Correct Answer
                    </FormLabel>
                    <Input
                      value={correctAnswer}
                      onChange={(e) => setCorrectAnswer(e.target.value)}
                      placeholder="Enter correct answer"
                      focusBorderColor="teal.500"
                      _hover={{ boxShadow: "md" }}
                      _focus={{ boxShadow: "lg" }}
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" color="teal.600">
                      Marks
                    </FormLabel>
                    <Input
                      type="number"
                      value={marks}
                      onChange={(e) => setMarks(Number(e.target.value))}
                      placeholder="Enter marks"
                      focusBorderColor="teal.500"
                      _hover={{ boxShadow: "md" }}
                      _focus={{ boxShadow: "lg" }}
                    />
                  </FormControl>
                </VStack>

                <Divider my={6} />

                <Button
                  colorScheme="teal"
                  size="lg"
                  width="full"
                  rounded="lg"
                  onClick={handleAddQuestion}
                  leftIcon={<AddIcon />}
                  _hover={{
                    bg: "teal.700",
                    transform: "scale(1.05)",
                  }}
                  _active={{ transform: "scale(0.95)" }}
                >
                  Add Question
                </Button>
              </Box>
            </Box>

            <Divider />

            {/* Questions List */}
            <Box>
              <Heading
                size="md"
                mb={4}
                display="flex"
                alignItems="center"
                mt={4}
              >
                <Box as="span" mr={2} color="purple.500">
                  <Icon as={ViewIcon} />
                </Box>
                <h1>Current Questions</h1>
              </Heading>
              {questions.length > 0 ? (
                <VStack spacing={4} align="stretch">
                  {questions.map((question, index) => (
                    <HStack
                      key={question._id}
                      justify="space-between"
                      bg="gray.100"
                      p={4}
                      borderRadius="md"
                      boxShadow="sm"
                      _hover={{ bg: "gray.200" }}
                    >
                      <VStack align="start" spacing={1}>
                        <Text>Question Number: {index + 1}</Text>
                        <Text fontWeight="bold">{question.questionText}</Text>
                        <Text fontSize="sm" color="gray.500">
                          Type: {question.questionType} | Marks:{" "}
                          {question?.marks ||0}
                        </Text>
                      </VStack>
                      <Tooltip label="Delete Question" fontSize="sm">
                        <IconButton
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleDeleteQuestion(question._id)}
                          aria-label="Delete Question"
                        />
                      </Tooltip>
                    </HStack>
                  ))}
                </VStack>
              ) : (
                <Text>No questions added yet.</Text>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ManageAssessments;

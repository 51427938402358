import { appState } from "../state/state";

export const appReducer = (state = appState, action) => {
  switch (action.type) {
    case "SET_USER_ID": {
      return {
        ...state,
        userId: action.value,
      };
    }
    case "SET_EMPLOYER_ID": {
      return {
        ...state,
        employerId: action.value,
      };
    }
    case "SET_USER": {
      return {
        ...state,
        user: action.value,
      };
    }
    case "SET_EMPLOYER": {
      return {
        ...state,
        employer: action.value,
      };
    }
    default:
      return state;
  }
};

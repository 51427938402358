// src/App.js
import "./App.css";
import Home from "./components/pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import ContactUs from "./components/pages/ContactUs";
import FAQs from "./components/pages/FAQs";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Login from "./components/pages/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Register from "./components/pages/Register";
import Layout from "./components/pages/Layout";
import Profile from "./components/dashboard/Profile";
import NotFound from "./components/pages/NotFound";
import ForgotPassword from "./components/pages/ForgotPassword";
import AiInterview from "./components/dashboard/interview/AiInterview";
import InterviewAnalytics from "./components/dashboard/interview/InterviewAnalytics";
import MockInterviewPractice from "./components/blogs/MockInterviewPractise";
import MockInterviewOnline from "./components/blogs/MockInterviewOnline";
import MockInterviewQuestions from "./components/blogs/MockInterviewQuestions";
import MockInterviewWithAI from "./components/blogs/MockInterviewAI";
import InterviewMockBlogPost from "./components/blogs/InterviewMock";
import InterviewPreparationBlogPost from "./components/blogs/InterviewPraparation";
import InterviewMockTestBlog from "./components/blogs/InterviewMockTest";
import InterviewWarmupBlogPost from "./components/blogs/InterviewWarmup";
import InterviewTipsBlog from "./components/blogs/InterviewTips";
import InterviewPrepBlogPost from "./components/blogs/InterviewPrep";
import BestInterviewPreparationBlogPost from "./components/blogs/BestInterviewPreparation";
import BestInterviewIntroductionBlogPost from "./components/blogs/BestInterviewIntroduction";
import BestInterviewQuestionsAndAnswersBlogPost from "./components/blogs/BestInterviewQuestionsAndAnswers";
import BestInterviewPreparationSitesBlogPost from "./components/blogs/BestInterviewPreparationSites";
import BestInterviewPreparationCourseBlogPost from "./components/blogs/BestIntervierwPreparationCourse";
import BlogList from "./components/blogs/Blogs";
import EmployerLogin from "./components/pages/EmployerLogin";
import EmployerRegister from "./components/pages/EmployerRegister";
import EmployerForgotPassword from "./components/pages/EmployerForgotPassword";
import ManageAssessments from "./components/dashboard/assessment/ManageAsessments";
import InterviewAssessment from "./components/dashboard/assessment/InterviewAssessment";
import AssessmentAttempts from "./components/dashboard/assessment/AssessmentAttempts";

const App = () => {
  const location = useLocation();

  // List of routes where the layout should not be rendered
  const noLayoutRoutes = [
    "/dashboard",
    "/dashboard/profile",
    "/dashboard/ai-interview",
    "/dashboard/assessment",
    "/dashboard/assessment-attempts",
    "/dashboard/ai-interview-analytics",
  ];

  // Check if the current path matches any in the noLayoutRoutes list or includes a dynamic segment
  const isNoLayoutRoute = noLayoutRoutes.some((route) =>
    location.pathname.startsWith(route)
  );


  return (
    <>
      {/* Conditionally render Layout based on current path */}
      {isNoLayoutRoute ? (
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="/dashboard/profile" element={<Profile />} />
            <Route path="/dashboard/ai-interview" element={<AiInterview />} />
            <Route
              path="/dashboard/assessment"
              element={<ManageAssessments />}
            />
            <Route
              path="/dashboard/assessment-attempts"
              element={<AssessmentAttempts />}
            />
            <Route
              path="/dashboard/ai-interview/:assessmentId"
              element={<InterviewAssessment />}
            />
            <Route
              path="/dashboard/ai-interview-analytics"
              element={<InterviewAnalytics />}
            />
          </Route>
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/mock-interview-practice"
              element={<MockInterviewPractice />}
            />
            <Route
              path="/mock-interview-online"
              element={<MockInterviewOnline />}
            />
            <Route
              path="/mock-interview-questions"
              element={<MockInterviewQuestions />}
            />
            <Route
              path="/mock-interview-ai"
              element={<MockInterviewWithAI />}
            />
            <Route path="/interview-mock" element={<InterviewMockBlogPost />} />
            <Route
              path="/interview-preparation"
              element={<InterviewPreparationBlogPost />}
            />
            <Route
              path="/interview-mock-test"
              element={<InterviewMockTestBlog />}
            />
            <Route
              path="/interview-warmup"
              element={<InterviewWarmupBlogPost />}
            />
            <Route path="/interview-tips" element={<InterviewTipsBlog />} />
            <Route path="/interview-prep" element={<InterviewPrepBlogPost />} />
            <Route
              path="/best-interview-preparation"
              element={<BestInterviewPreparationBlogPost />}
            />
            <Route
              path="/best-interview-introduction"
              element={<BestInterviewIntroductionBlogPost />}
            />
            <Route
              path="/best-interview-questions-and-answers"
              element={<BestInterviewQuestionsAndAnswersBlogPost />}
            />
            <Route
              path="/best-interview-preparation-sites"
              element={<BestInterviewPreparationSitesBlogPost />}
            />
            <Route
              path="/best-interview-preparation-course"
              element={<BestInterviewPreparationCourseBlogPost />}
            />

            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/employer/login" element={<EmployerLogin />} />
            <Route
              path="/employer/forgot-password"
              element={<EmployerForgotPassword />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/employer/register" element={<EmployerRegister />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      )}
    </>
  );
};

export default App;

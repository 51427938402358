import React, { useEffect, useRef } from "react";
import {
  Box,
  VStack,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
  Button,
  Divider,
  Heading,
  useDisclosure,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  Text,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { FiEdit } from "react-icons/fi";
import {
  Outlet,
  NavLink,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import {
  FaUser,
  FaSignOutAlt,
  FaChartLine,
  FaClipboardCheck,
  FaClipboardList,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import FeatureList from "./FeaturesList";
import { hitBeApi } from "../../api/api";

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const isNoLayoutRoute = ["/dashboard"].includes(location.pathname);
  const navWidth = useBreakpointValue({ base: "100%", lg: "300px" });
  const navBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });
  const userDetailsFetched = useRef(false);
  const employerDetailsFetched = useRef(false);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const MotionVStack = motion(VStack);
  const userFromState = useSelector((state) => state.user);
  const employerFromState = useSelector((state) => state.employer);

  const toggleDrawer = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleLogout = () => {
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    toast({
      title: "Logout Success.",
      description: "Logout successful along-with browser cache removed.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userDetailsFetched.current) return;
      userDetailsFetched.current = true;
      try {
        const response = await hitBeApi("user", "GET");

        if (response) {
          dispatch({
            type: "SET_USER_ID",
            value: response.data.userDetails._id,
          });
          dispatch({
            type: "SET_USER",
            value: response.data.userDetails,
          });
        }
        toast({
          title: "User details retrieved successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const fetchEmployerData = async () => {
      if (employerDetailsFetched.current) return;
      employerDetailsFetched.current = true;
      try {
        const response = await hitBeApi("employer", "GET");
        if (response) {
          dispatch({
            type: "SET_EMPLOYER_ID",
            value: response?.data?.employerDetails._id,
          });
          dispatch({
            type: "SET_EMPLOYER",
            value: response?.data?.employerDetails,
          });
          toast({
            title: "Employer details retrieved successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchUserData();
    fetchEmployerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActive = (path) => location.pathname === path;

  const NavLinks = () => {
    const commonStyles = {
      size: "lg",
      width: "full",
      justifyContent: "flex-start",
    };

    const profileLinkStyles = {
      ...commonStyles,
      colorScheme: "blue",
      variant: "solid",
      _hover: { bg: "blue.500", color: "white" },
    };

    const interviewLinkStyles = {
      ...commonStyles,
      colorScheme: "teal",
      variant: "outline",
      borderLeft: "4px solid deepskyblue",
      pl: 4,
      _hover: { bg: "teal.100" },
    };

    const analyticsLinkStyles = {
      ...commonStyles,
      colorScheme: "green",
      variant: "ghost",
      _hover: { bg: "green.100" },
    };

    const assessmentLinkStyles = {
      ...commonStyles,
      colorScheme: "purple",
      variant: "outline",
      _hover: { bg: "purple.100" },
    };

    const logoutLinkStyles = {
      ...commonStyles,
      colorScheme: "red",
      variant: "solid",
      _hover: { bg: "red.600", color: "white" },
    };


    return (
      <>
        <NavLink to="profile" exact="true">
          <Button
            leftIcon={<FaUser />}
            {...profileLinkStyles}
            isActive={isActive("/profile")}
          >
            Profile
          </Button>
        </NavLink>

        <NavLink
          to="ai-interview"
          exact="true"
          display={userFromState ? "block" : "none"}
        >
          <Button
            leftIcon={<FiEdit />}
            {...interviewLinkStyles}
            isActive={isActive("/ai-interview")}
            display={userFromState ? "block" : "none"}
          >
            AI Interview
          </Button>
        </NavLink>

        <NavLink
          to="ai-interview-analytics"
          exact="true"
          display={
            userFromState && Object.keys(userFromState).length > 0
              ? "block"
              : "none"
          }
        >
          <Button
            leftIcon={<FaChartLine />}
            {...analyticsLinkStyles}
            isActive={isActive("/ai-interview-analytics")}
            display={
              userFromState && Object.keys(userFromState).length > 0
                ? "block"
                : "none"
            }
          >
            Interviews Analytics
          </Button>
        </NavLink>

        <NavLink to="assessment" exact="true">
          <Button
            leftIcon={<FaClipboardCheck />}
            {...assessmentLinkStyles}
            isActive={isActive("/assessment")}
            display={
              employerFromState && Object.keys(employerFromState).length > 0
                ? "block"
                : "none"
            }
          >
            Create Assessment
          </Button>
        </NavLink>

        <NavLink to="assessment-attempts" exact="true">
          <Button
            leftIcon={<FaClipboardList />}
            {...assessmentLinkStyles}
            isActive={isActive("/assessment-attempts")}
            display={
              employerFromState && Object.keys(employerFromState).length > 0
                ? "block"
                : "none"
            }
          >
            Assessment Attempts
          </Button>
        </NavLink>

        <Button
          leftIcon={<FaSignOutAlt />}
          {...logoutLinkStyles}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </>
    );
  };

  return (
    <HStack
      w="100%"
      minH="100vh"
      align="start"
      spacing={0}
      bg={useColorModeValue("gray.50", "gray.900")}
    >
      {isLargeScreen ? (
        <MotionVStack
          as="nav"
          align="stretch"
          spacing={{ base: 1, md: 1, lg: 2 }}
          p={4}
          bg={navBg}
          color={textColor}
          minW={navWidth}
          w={navWidth}
          borderRight="1px"
          borderColor={"gray.400"}
          minHeight={"110vh"}
          display={"flex"}
          flexDirection={"column"}
          initial={{ x: -250 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          whileHover={{ scale: 1.02 }}
          position={"fixed"}
        >
          <Link to="/">
            <HStack spacing={2} cursor="pointer">
              {" "}
              {/* Adds horizontal spacing between the logo and text */}
              <Image
                src="/logo.png" // Reference to the image in the public folder
                alt="Logo"
                boxSize="40px" // Adjust size of the logo
              />
              <Heading
                size={{ base: "lg", lg: "md" }}
                mb={{ base: 1, lg: 2 }}
                pl={2}
                mt={{ base: 1, lg: 2 }}
              >
                TryInterview AI
              </Heading>
            </HStack>
          </Link>
          <Divider />
          <NavLinks />
        </MotionVStack>
      ) : (
        <>
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            onClick={toggleDrawer}
            m={4}
            fontWeight={"bold"}
            position={"absolute"}
          />
          <Text
            right={"5%"}
            position={"absolute"}
            m="4"
            fontSize={"x-large"}
            fontWeight={"bold"}
          >
            TryInterview AI
          </Text>
          <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>TryInterview AI</DrawerHeader>
                <DrawerBody>
                  <VStack align="stretch" spacing={{ base: 1, lg: 2 }}>
                    <NavLinks />
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </>
      )}

      {!isNoLayoutRoute ? (
        <Box
          flex="1"
          width={{ base: "100%", lg: "80%" }}
          mt={{ base: "15%", md: "10%", lg: "0%" }}
          position={"absolute"}
          left={{ base: "0px", lg: "300px" }}
          px="auto"
        >
          <Outlet />
        </Box>
      ) : (
        <FeatureList></FeatureList>
      )}
    </HStack>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { Box, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";

const AssessmentEditForm = ({ currentAssessment, onSave }) => {
  const [title, setTitle] = useState(currentAssessment?.title || "");
  const [description, setDescription] = useState(
    currentAssessment?.description || ""
  );

  const handleSave = () => {
    onSave({ title, description });
  };

  return (
    <Box>
      <FormControl mb={4} isRequired>
        <FormLabel>Title</FormLabel>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter assessment title"
        />
      </FormControl>

      <FormControl mb={4} isRequired>
        <FormLabel>Description</FormLabel>
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter assessment description"
        />
      </FormControl>

      <Button colorScheme="blue" onClick={handleSave} mt={4} w={"100%"}>
        Save Title & Desc
      </Button>
    </Box>
  );
};

export default AssessmentEditForm;
